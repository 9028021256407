import {
  FunctionComponent,
  useMemo,
  type CSSProperties,
  useState, useRef, useEffect,
} from "react";

type ChatInputType = {
  fillSendSolid?: string;

  /** Style props */
  versionsupportAttachnoSaPadding?: CSSProperties["padding"];
  versionsupportAttachnoSaGap?: CSSProperties["gap"];
  messageGap?: CSSProperties["gap"];
  cursorWidth?: CSSProperties["width"];
  cursorHeight?: CSSProperties["height"];
  divFontSize?: CSSProperties["fontSize"];
  divLineHeight?: CSSProperties["lineHeight"];
  fillSendSolidWidth?: CSSProperties["width"];
  fillSendSolidHeight?: CSSProperties["height"];

  sendMessage?: (message: string) => void;
};

const ChatInput: FunctionComponent<ChatInputType> = ({
  fillSendSolid,
  versionsupportAttachnoSaPadding,
  versionsupportAttachnoSaGap,
  messageGap,
  cursorWidth,
  cursorHeight,
  divFontSize,
  divLineHeight,
  fillSendSolidWidth,
  fillSendSolidHeight,
  sendMessage,
}) => {
  const [message, setMessage] = useState("");
  const inputRef = useRef<null|HTMLInputElement>(null)
  const versionsupportAttachnoSaStyle: CSSProperties = useMemo(() => {
    return {
      padding: versionsupportAttachnoSaPadding,
      gap: versionsupportAttachnoSaGap,
    };
  }, [versionsupportAttachnoSaPadding, versionsupportAttachnoSaGap]);

  const messageStyle: CSSProperties = useMemo(() => {
    return {
      gap: messageGap,
    };
  }, [messageGap]);

  const cursorStyle: CSSProperties = useMemo(() => {
    return {
      width: cursorWidth,
      height: cursorHeight,
    };
  }, [cursorWidth, cursorHeight]);

  const divStyle: CSSProperties = useMemo(() => {
    return {
      fontSize: divFontSize,
      lineHeight: divLineHeight,
    };
  }, [divFontSize, divLineHeight]);

  const fillSendSolidStyle: CSSProperties = useMemo(() => {
    return {
      width: fillSendSolidWidth,
      height: fillSendSolidHeight,
    };
  }, [fillSendSolidWidth, fillSendSolidHeight]);

  const send = () => {
    if (sendMessage && message) {
      sendMessage(message);
      setMessage("");
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <form
      className="bg-bg-primary box-border flex flex-row items-start justify-start py-3 pr-4 pl-6 gap-[16px] text-left text-sm text-text-disabled font-body-12px-16lh-medium self-stretch border-t-[1px] border-solid border-border-tertiary rounded-b-[16px]"
      style={versionsupportAttachnoSaStyle}
      onSubmit={(e) => {
        e.preventDefault();
        send();
      }}
    >
      <div
        className="self-stretch flex-1 flex flex-row items-center justify-start gap-[2px]"
        style={messageStyle}
      >
        <input
          value={message}
          ref={inputRef}
          onChange={(e) => setMessage(e.currentTarget.value)}
          placeholder="Напишите сообщение"
          className="flex-1 relative leading-[20px]"
          style={{...divStyle, color: '#000'}}
        />
      </div>
      <span
        className="relative w-6 h-6 object-cover cursor-pointer"
        style={fillSendSolidStyle}
        onClick={send}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.04971 12.9528L2.66801 18.7977C2.50434 19.0806 2.40736 19.4033 2.4416 19.7287C2.51105 20.3887 2.74877 20.8624 3.33908 21.0004C3.59213 21.0595 3.85317 20.9865 4.09027 20.8798L21.0759 13.2331C21.3414 13.1135 21.5625 12.9176 21.6 12.6277C21.6461 12.2714 21.6463 11.7798 21.6006 11.4216C21.5631 11.1277 21.3394 10.9285 21.0702 10.8074L4.09055 3.16328C3.85327 3.05646 3.59201 2.98341 3.33879 3.0427C2.74871 3.18087 2.51105 3.65447 2.4416 4.31429C2.40735 4.63971 2.50435 4.96236 2.66804 5.24527L6.04968 11.0897C6.1081 11.1907 6.15628 11.2958 6.19422 11.4036H13.9997C14.3393 11.4036 14.6146 11.6803 14.6146 12.0215C14.6146 12.3627 14.3393 12.6393 13.9997 12.6393H6.19405C6.15615 12.747 6.10803 12.8519 6.04971 12.9528Z"
            fill={message.length ? "#ae4cfb" : "#C6C6D0"}
          />
        </svg>
      </span>
    </form>
  );
};

export default ChatInput;
